import React, { useEffect, useState, useCallback } from 'react';
import { Switch, Route, Redirect, useRouteMatch, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { NavTop } from './components';
import {
  LoginPage,
  ForgotPassword,
  CreateAccount,
  Clients,
  Education,
  Libraries,
  Groups,
  DeleteInstruction,
} from './pages';

import nasmApi from './api/endpoints';
import { LOGIN_STATES, logout } from './reducers/currentUserReducer';
import { CREATE_ACCOUNT_ENABLED } from './util/loginConstants';
import VerifyTermsPrivacy from './components/VerifyTermsPrivacy';

import {
  resetWidget,
  defaultHideContactFormFields,
  prefillDefaultFormFields,
  hideIdentityContactFormFields,
  identifyContactForm,
  prefillFormFields,
} from './util/freshdeskWidget';
import DeleteAccount from './pages/DeleteAccount';
import Dashboard from './pages/ccDashboard';

// Menu Routes
let routes = {
  libraries: [
    'exercises',
    'workouts',
    'programs',
  ],
  groups: [],
};



const Router = () => {
  const loginState = useSelector(state => state.currentUser.loginState);
  const currentUser = useSelector(state => state.currentUser);
  const loggedIn = loginState === LOGIN_STATES.LOGIN;

  const dispatch = useDispatch();
  const location = useLocation();

  const section = useRouteMatch('/:section');
  const subSection = useRouteMatch('/:section/:subSection');

  const [showTcWarning, setShowTcWarning] = useState(false);
  const [requiresTc, setRequiresTc] = useState(false);
  const [requiresPp, setRequiresPp] = useState(false);

  const [loading, setLoading] = useState(false);

  const isMaintenanceMode = process.env.REACT_APP_MAINTENANCE_MODE === 'true';
  const currentUserManager = JSON.parse(localStorage.getItem('AUTH_TOKEN'));

  if(currentUserManager?.cc_manager){
    routes = Object.assign({ dashboard: [] }, routes);
  }else{
    routes = Object.assign({ clients: [] }, routes);
  }



  // Determine if a logged-in trainer has accepted the updated
  // terms and conditions and privacy policy
  // before navigating anywhere else in the website
  useEffect(() => {
    if (loggedIn && !isMaintenanceMode) {
      nasmApi.users.checkTermsAndPrivacyPolicyAccepted(currentUser)
        .then((result) => {
          const { requires_pp_update: requiresPrivacy, required_tc_update: requiresTerms } = result.result;
          if (requiresTerms) {
            setRequiresTc(true);
            setShowTcWarning(true);
          }

          if (requiresPrivacy) {
            setRequiresPp(true);
            setShowTcWarning(true);
          }
        });
    }
  }, [loggedIn, currentUser, isMaintenanceMode]);

  // Conditionally show the feedback widget when the user is
  useEffect(() => {
    // If not logged in, this will reset the form so the "name" and "email" fields will be available since we won't
    // know otherwise who is submitting the feedback.
    resetWidget();
    defaultHideContactFormFields();
    prefillDefaultFormFields(process.env.REACT_APP_DEPLOY_ENV, loggedIn);

    // If user is logged in, gather extra data to prefill contact form with and included identity info.
    // Also hides the name and email fields since we already have that info.
    if (loggedIn) {
      hideIdentityContactFormFields();
      identifyContactForm(currentUser.email, currentUser.full_name);
      prefillFormFields(process.env.REACT_APP_DEPLOY_ENV, loggedIn, currentUser);
    } else {
      // ONLY uncomment if we are hiding feedback button until user logs in.
      // toggleWidgetVisibility(false);
    }
  }, [currentUser, loggedIn]);

  const getActiveRoute = () => {
    return `${section?.params?.section}`;
  };

  const getSubRoutes = () => {
    return routes[section?.params?.section];
  };

  // eslint-disable-next-line no-unused-vars
  const getActiveSubroute = () => {
    return subSection?.params?.subSection;
  };

  const handleCloseVerifyTerms = useCallback(() => {
    dispatch(logout());
    setRequiresTc(false);
    setRequiresPp(false);
    setShowTcWarning(false);
  }, [dispatch]);

  const handleAcceptTerms = () => {
    setRequiresTc(false);
    if (!requiresPp) {
      setLoading(true);
      nasmApi.users.acceptTermsAndPrivacyPolicy().then(() => {
        setLoading(false);
        setRequiresPp(false);
        setShowTcWarning(false);
      });
    }
  };

  const handleAcceptPrivacy = () => {
    setRequiresPp(false);
    if (!requiresTc) {
      setLoading(true);
      nasmApi.users.acceptTermsAndPrivacyPolicy().then(() => {
        setLoading(false);
        setRequiresPp(false);
        setShowTcWarning(false);
      });
    }
  };

  return (
    <>
      <VerifyTermsPrivacy
        visible={showTcWarning}
        requiresTc={requiresTc}
        requiresPp={requiresPp}
        onClose={handleCloseVerifyTerms}
        handleAcceptTerms={handleAcceptTerms}
        handleAcceptPrivacy={handleAcceptPrivacy}
        loading={loading}
      />
      <Switch>
        {/* Unauthenticated Routes */}
        <Route path='/login'><LoginPage /></Route>
        <Route path='/forgotpassword'><ForgotPassword /></Route>
        <Route path='/create-account'>
          {
            CREATE_ACCOUNT_ENABLED
              ? <CreateAccount />
              : <Redirect to='/login' />
          }
        </Route>
        <Route path='/deleteinstruction'><DeleteInstruction /></Route>
        <Route path='/delete-account'><DeleteAccount /></Route>
        {/* Authenticated Routes */}
        <Route path='/'>
          {/* Render header for all routes or redirect if not logged in */}
          {
            loggedIn ? <NavTop activeRoute={getActiveRoute()} routes={Object.keys(routes)} subRoutes={getSubRoutes()} />
              : <Redirect
                  to={{
                    pathname: "/login",
                    state: { redirectFrom: location.pathname },
                  }}
                />
          }
          {loggedIn &&
          <Switch>
            {/* Top Navigation Menu Routes */}
            <Route path='/clients'><Clients /></Route>
            <Route path='/dashboard'><Dashboard /></Route>
            <Route path='/education'><Education /></Route>
            <Route path='/libraries'><Libraries /></Route>
            <Route path='/groups'><Groups /></Route>
            {/* Default route */}
            { currentUserManager?.cc_manager ? (
              <>              
              <Route path='/' exact><Redirect to='/dashboard' /></Route>
              <Route path='/*'><Redirect to='/dashboard' /></Route>
              </>
              ) : 
              <>
              <Route path='/' exact><Redirect to='/clients' /></Route>
              <Route path='/*'><Redirect to='/clients' /></Route>
              </>
              }
            {/* Redirects to Clients if top most path isn't a supported option to navigate to */}
            
          </Switch>}
        </Route>
      </Switch>
    </>
  );
};

export default Router;
